import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { SideNavigation, Header } from 'item-component'
import { MainRouter } from '../globalComponents/MainRouter'
import { LogIn } from './common/LogIn'
import { Unauthorized } from './common/Unauthorized'
import { LoadingProfile } from './common/LoadingProfile'
import { useMain } from './useMain'
import Banner from './common/Banner'

export const Main = () => {
  const {
    loading,
    error,
    data,
    constructedMenu,
    bannerHeader,
    getUserProfile,
  } = useMain()
  const appMenus = constructedMenu()
  const auth = useAuth()
  const { isAuthenticated } = auth
  const bannerData = bannerHeader ? bannerHeader?.bannerDetail : []
  // const isItemhubDomain = window.location.host.includes('itemhub') // TODO: Itemhub Changes
  if (isAuthenticated()) {
    if (loading) return <LoadingProfile />
    if (error) return <Unauthorized />
    if (data) {
      return (
        <Layout
          theme="dark" // configure theme here
          darkMode="false" // configure theme here
          data-testid="mainAuthenticatedLayout"
        >
          <>
            {bannerData &&
              bannerData.map((banner, index) => {
                return <Banner key={index} bannerData={banner} />
              })}
            <Header
              userRole={
                getUserProfile?.roles ? getUserProfile.roles?.join() : ''
              }
              useAuth={useAuth}
              appTitle={'Launchpad'} //  TODO: For Itemhub --> Need Comment this line and Enable the below line
              // appTitle={isItemhubDomain ? 'ItemHub' : 'Launchpad'} // TODO: Itemhub Changes
            />
            {getUserProfile?.roles?.length > 0 && (
              <SideNavigation
                className="side-navigation-panel"
                data-testid="item-app-sidenav-panel"
                startExpanded={false}
                appMenus={appMenus}
                defaultmenurequired={false} //  TODO: For Itemhub --> Need Comment this line and Enable the below line
                // defaultmenurequired={isItemhubDomain}  // TODO: Itemhub Changes
                isMinimized={true}
              />
            )}
          </>

          <MainRouter getUserProfile={getUserProfile} />
        </Layout>
      )
    }
  } else {
    return <LogIn />
  }
}
