// import { itemCommonUtilis } from 'item-component'  // TODO: Itemhub Changes
import { LoginRedirectPath } from '../globalUtils/searchUtills'
export const envExternalConfigs = {
  local: {
    auth: {
      loginRedirect: `${window.location.origin}/auth/login`,
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl: `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html`,
    },
    analytics: {
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      url: 'http://localhost:9001/graphql',
    },
    launchpad: {
      host: 'http://localhost:3000',
    },
    support: {
      host: 'https://www.partnersonline.com/page/help',
    },
  },
  dev: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl: `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html`,
      // loginRedirect: LoginRedirectPath(), // TODO: Itemhub Changes
    },
    analytics: {
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      uri: 'https://stage-api.target.com/graphql_launchpad/v1',
    },
    launchpad: {
      host: 'https://xycpre-launchpad.partnersonline.com/v2', //  TODO: For Itemhub --> Need Comment this line and Enable the below line
      // host: `${itemCommonUtilis.findAppPath()}/v2`, // TODO: Itemhub Changes
    },
    support: {
      host: 'https://www.partnersonline.com/page/help',
    },
  },
  stg: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl: `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html`,
      loginRedirect: LoginRedirectPath(),
    },
    analytics: {
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      uri: 'https://stage-api.target.com/graphql_launchpad/v1',
    },
    launchpad: {
      host: 'https://xycpre-launchpad.partnersonline.com/v2', //  TODO: For Itemhub --> Need Comment this line and Enable the below line
      // host: `${itemCommonUtilis.findAppPath()}/v2`, // TODO: Itemhub Changes
    },
    support: {
      host: 'https://www.partnersonline.com/page/help',
    },
  },
  prod: {
    auth: {
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl: `https://oauth.iam.partnersonline.com/login/responses/logoff.html`,
      loginRedirect: LoginRedirectPath(),
    },
    analytics: {
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    graphql: {
      uri: 'https://api.target.com/graphql_launchpad/v1',
    },
    launchpad: {
      host: 'https://launchpad.partnersonline.com/v2', //  TODO: For Itemhub --> Need Comment this line and Enable the below line
      // host: `${itemCommonUtilis.findAppPath()}/v2`, // TODO: Itemhub Changes
    },
    support: {
      host: 'https://www.partnersonline.com/page/help',
    },
  },
}
